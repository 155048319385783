import {
  Box, Grid2, IconButton, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import {
  FiFacebook, FiInstagram, FiYoutube,
} from 'react-icons/fi';
import { SiTiktok } from 'react-icons/si';
import { DateTime } from 'luxon';
import React from 'react';
import { TypographyElement } from '@plugins/next-cms-core';
import useConsentManager from '@lib/hooks/useConsentManager';
import { useTranslation } from 'next-i18next';
import Link from '@components/atoms/Link';
import Image from '@components/atoms/Image2';
import BrandLogo from '@components/molecules/BrandLogo';
import Container from '@components/atoms/Container';
import FooterMenu from '../molecules/FooterMenu';

const basePadding = 80;
const gridBreakpoints = {
  xs: 12,
  sm: 6,
  md: 3,
  lg: 3,
};

export default function Footer({
  data,
  config,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { changeConsent } = useConsentManager();

  const {
    branches,
    siteConfig,
    pageBottomConfig,
    navigationFooter = [],
  } = data ?? {};
  const { globalInfoText } = pageBottomConfig?.data?.attributes ?? {};
  const { bottomInfoText } = config ?? {};
  const {
    sidebarUrlCarsearch,
    sidebarUrlLocations,
    sidebarUrlWorkshop,
  } = siteConfig?.data?.attributes ?? {};

  const handleChangeConsent = (event) => {
    event.preventDefault();
    event.stopPropagation();

    changeConsent();
  };

  return (
    <Box
      sx={{
        contentVisibility: 'auto',
        containIntrinsicSize: '804px',
        background: theme.palette.text.primary,
        paddingTop: `${basePadding / 2}px`,
        paddingBottom: `${basePadding * 2}px`,
      }}
    >
      <Container>
        {(globalInfoText || bottomInfoText) && (
          <Box
            sx={{
              ...SEPARATOR_STYLE,
              color: theme.palette.primary.contrastText,
              opacity: 0.65,
              paddingBottom: theme.spacing(2),
              marginBottom: theme.spacing(5),
            }}
          >
            {bottomInfoText && (
              <TypographyElement
                color="inherit"
                component="p"
                data={{
                  displayVariant: 'caption',
                  value: bottomInfoText,
                }}
                paragraph
              />
            )}
            {globalInfoText && (
              <TypographyElement
                color="inherit"
                component="p"
                data={{
                  displayVariant: 'caption',
                  value: globalInfoText,
                }}
                paragraph
              />
            )}
          </Box>
        )}
        <Grid2 container spacing={4} sx={{ mb: 2 }}>
          <Grid2 size={gridBreakpoints}>
            <BrandLogo
              alt=""
              color="inverted"
              style={{
                width: 'auto',
                height: 58,
              }}
            />
            <Box mt={3}>
              <a
                href="https://www.provenexpert.com/de-de/csb-schimmel-automobile-gmbh/"
                rel="noreferrer"
                target="_blank"
                title="Kundenbewertungen &amp; Erfahrungen zu CSB Schimmel Automobile GmbH. Mehr Infos anzeigen."
              >
                <Image
                  alt="Erfahrungen &amp; Bewertungen zu CSB Schimmel Automobile GmbH"
                  data-name="provenexpert"
                  height={200}
                  loading="lazy"
                  src="https://images.provenexpert.com/2e/6e/66449c43267c6ff417912012d2b7/widget_square_200_de_0.png"
                  width={200}
                />
              </a>
            </Box>
            <Box mt={1}>
              <Link href="https://www.facebook.com/csb.berlin/" legacyBehavior>
                <IconButton
                  aria-label="Facebook"
                  color="secondary"
                  component="a"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <FiFacebook />
                </IconButton>
              </Link>
              <Link href="https://www.youtube.com/channel/UC2vfkFLsmJEsHUuYkFp7UHA" legacyBehavior>
                <IconButton
                  aria-label="Youtube"
                  color="secondary"
                  component="a"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <FiYoutube />
                </IconButton>
              </Link>
              <Link href="https://www.instagram.com/schimmelautomobile/" legacyBehavior>
                <IconButton
                  aria-label="Instagram"
                  color="secondary"
                  component="a"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <FiInstagram />
                </IconButton>
              </Link>
              <Link href="https://www.tiktok.com/@schimmelautomobile" legacyBehavior>
                <IconButton
                  aria-label="TikTok"
                  color="secondary"
                  component="a"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <SiTiktok style={{
                    width: 20,
                    height: 20,
                  }}
                  />
                </IconButton>
              </Link>
            </Box>
          </Grid2>
          <Grid2 size={gridBreakpoints}>
            <Typography
              color="secondary"
              component="div"
              sx={{
                mb: 3,
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  width: 20,
                  height: 20,
                  marginRight: theme.spacing(1),
                },
                transform: `translateX(-${theme.spacing(1) + 20}px)`,
              }}
              variant="h6"
            >
              {t('components.organisms.Footer.service')}
            </Typography>
            <FooterMenu
              items={[
                {
                  label: t('components.organisms.Footer.vehicleSearch'),
                  href: sidebarUrlCarsearch,
                },
                {
                  label: t('components.organisms.Footer.workshop'),
                  href: t('components.organisms.Footer.urls.workshop'),
                },
                {
                  label: t('components.organisms.Footer.hyundai'),
                  href: t('components.organisms.Footer.urls.hyundai'),
                  className: 'hyundai_tag',
                },
                {
                  label: t('components.organisms.Footer.mgMotor'),
                  href: t('components.organisms.Footer.urls.mgMotor'),
                  className: 'mgmotor_tag',
                },
                {
                  label: t('components.organisms.Footer.mitsubishi'),
                  href: t('components.organisms.Footer.urls.mitsubishi'),
                  className: 'mitsubishi_tag',
                },
                {
                  label: t('components.organisms.Footer.fuso'),
                  href: t('components.organisms.Footer.urls.fuso'),
                  className: 'fuso_tag',
                },
                {
                  label: t('components.organisms.Footer.maxus'),
                  href: t('components.organisms.Footer.urls.maxus'),
                  className: 'maxus_tag',
                },
              ]}
            />
          </Grid2>
          <Grid2 size={gridBreakpoints}>
            <Typography
              color="secondary"
              component="div"
              sx={{
                mb: 3,
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  width: 20,
                  height: 20,
                  marginRight: theme.spacing(1),
                },
                transform: `translateX(-${theme.spacing(1) + 20}px)`,
              }}
              variant="h6"
            >
              {branches.data.length}
              {' '}
              {t('components.organisms.Footer.locations')}
            </Typography>
            <FooterMenu
              items={branches.data.map((branch) => ({
                label: branch.attributes.name,
                href: branch.attributes.pageUrl,
              }))}
            />
          </Grid2>
          <Grid2 size={gridBreakpoints}>
            <Typography
              color="secondary"
              component="div"
              sx={{
                mb: 3,
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  width: 20,
                  height: 20,
                  marginRight: theme.spacing(1),
                },
                transform: `translateX(-${theme.spacing(1) + 20}px)`,
              }}
              variant="h6"
            >
              {t('components.organisms.Footer.more')}
            </Typography>
            <FooterMenu
              items={[
                {
                  label: t('components.organisms.Footer.career'),
                  href: t('components.organisms.Footer.urls.career'),
                },
                {
                  label: t('components.organisms.Footer.contact'),
                  href: sidebarUrlLocations,
                },
                {
                  label: t('components.organisms.Footer.dataProtection'),
                  href: t('components.organisms.Footer.urls.dataProtection'),
                },
                {
                  label: t('components.organisms.Footer.cookie'),
                  onClick: handleChangeConsent,
                },
                {
                  label: t('components.organisms.Footer.imprint'),
                  href: t('components.organisms.Footer.urls.imprint'),
                },
                {
                  label: t('components.organisms.Footer.vehicleRepairConditions'),
                  href: t('components.organisms.Footer.urls.vehicleRepairConditions'),
                },
                {
                  label: t('components.organisms.Footer.newCars'),
                  href: t('components.organisms.Footer.urls.newCars'),
                },
                {
                  label: t('components.organisms.Footer.usedCars'),
                  href: t('components.organisms.Footer.urls.usedCars'),
                },
                {
                  label: t('components.organisms.Footer.partsSalesConditions'),
                  href: t('components.organisms.Footer.urls.partsSalesConditions'),
                },
              ]}
            />
          </Grid2>
        </Grid2>
        <Box
          sx={{
            ...SEPARATOR_STYLE,
          }}
        />
        <Box
          mt={3}
          sx={{
            color: theme.palette.primary.contrastText,
            opacity: 0.65,
          }}
        >
          <Typography color="inherit" component="p" variant="caption">
            &copy;
            {' '}
            {DateTime.local().year}
            {' '}
            {t('components.organisms.Footer.description')}
          </Typography>
          <Grid2 container spacing={isMobile ? 0 : 4}>
            {navigationFooter.map((item) => (
              <Grid2
                key={item.uiRouterKey}
                size={{
                  md: 'auto',
                  xs: 12,
                }}
              >
                <Link href={item.path}>
                  <Typography color="inherit" component="a" variant="caption">
                    {item.title}
                  </Typography>
                </Link>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
}

const SEPARATOR_STYLE = {
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderBottomColor: 'rgba(255,255,255,0.1)',
};
